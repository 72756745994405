import React from "react"
import * as styles from "./Button.module.scss"

const Button = ({
  children = "",
  isPrimary,
  className,
  isLean,
  borderless,
  marginLeft,
  ...props
}) => {
  const isLeanClassName = isLean ? styles.lean : ""
  const isBorderlessClassName = borderless ? styles.borderless : ""
  const marginLeftClassName = marginLeft ? styles.marginLeft : ""
  return (
    <button
      {...props}
      className={[
        styles.button,
        isPrimary ? styles.filled : styles.outline,
        className,
        isLeanClassName,
        isBorderlessClassName,
        marginLeftClassName,
      ].join(" ")}
    >
      {children}
    </button>
  )
}

export default Button
