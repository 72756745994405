import { useEffect } from "react";

export default function useOnClickOutside(ref, handler) {
  useEffect(() => {
    const listener = (e) => {
      // Do nothing if clicking ref's element or descendent elements
      const currentEl = ref.current || ref;
      const targetEl = e.target;
      if (!currentEl || currentEl.contains(targetEl)) return;
      if (handler) handler(e);
    };

    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    document.addEventListener("canonic.graphclick", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
      document.removeEventListener("canonic.graphclick", listener);
    };
  }, [ref, handler]);
}
