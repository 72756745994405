import React from "react"
import Link from "gatsby-plugin-transition-link"
import getAppUrl from "@src/utils/getAppUrl"
import { Switch, Case } from "@src/components/SwitchCase"
import { DISCORD_INVITE } from "@src/const"
import { TESTS } from "@src/data/test"

import Button from "../Button"
import HeaderHamburger from "./components/HeaderHamburger"
import HeaderLink from "./components/HeaderLink"
import HeaderGridItem from "./components/HeaderGridItem"
import * as styles from "./Header.module.scss"
import useSlidingDropdown from "./useSlidingDropdown"
import useTest from "@src/hooks/useTest"
import BlogHeader from "@src/layouts/V2/Docs/components/Header"
import { trackV2 } from "@src/utils/track"

import { ReactComponent as CrossIcon } from "@src/icons/cross.svg"
import WebsitesIcon from "@src/icons/menu/websites.svg"
import AppsIcon from "@src/icons/menu/apps.svg"
import ContactIcon from "@src/icons/menu/contact.svg"
import DiscordIcon from "@src/icons/discord.blue.svg"
import YouTubeIcon from "@src/icons/v2/youtube-integrations.svg"

const DYNAMIC_NOTIFICATION_URL =
  "https://dynamic-notifications.can.canonic.dev/api/campaignNotifications/30-days-of-canonic"
const SAAS_PACK_HEADER_URL =
  "https://app.canonic.dev/signup?utm_source=canonic_landing_page&utm_medium=organic_canonic&utm_campaign=30daysofcanonic_uc_1&utm_term=header_start_for_free&utm_content=day_7"
const SAAS_PACK_LOGIN_URL =
  "https://app.canonic.dev/login?utm_source=canonic_landing_page&utm_medium=organic_canonic&utm_campaign=30daysofcanonic_uc_1&utm_term=header_login&utm_content=day_7"

const INTEGRATIONS_PACK_HEADER_URL =
  "https://app.canonic.dev/signup?utm_source=canonic_landing_page&utm_medium=organic_canonic&utm_campaign=30daysofcanonic_uc_3&utm_term=header_signup&utm_content=day_21"
const INTEGRATIONS_PACK_LOGIN_URL =
  "https://app.canonic.dev/login?utm_source=canonic_landing_page&utm_medium=organic_canonic&utm_campaign=30daysofcanonic_uc_3&utm_term=header_login&utm_content=day_21"

const MULTIPLE_DS_HEADER_URL =
  "https://app.canonic.dev/signup?utm_source=canonic_landing_page&utm_medium=organic_ca[…]anonic_uc_2&utm_term=header_start_for_free&utm_content=day_14"
const MULTIPLE_DS_LOGIN_URL =
  "https://app.canonic.dev/login?utm_source=canonic_landing_page&utm_medium=organic_can[…]30daysofcanonic_uc_2&utm_term=header_login&utm_content=day_14"

export default function Header({ location }) {
  const appUrl = getAppUrl.hook(location)
  const appLoginUrl = (appUrl || "").replace("signup", "login")
  const [dismissed, setDismissed] = React.useState(false)
  const [refresher, setRefresher] = React.useState()
  const [isActive, setIsActive] = React.useState(false)
  const [isWhite, setIsWhite] = React.useState(false)
  const [color, setColor] = React.useState("#000")
  const [menuVisible, setMenuVisible] = React.useState(false)
  const [isConsentGiven, setIsConsentGiven] = React.useState(true)
  const [notification, setNotification] = React.useState()
  const elementsRef = React.useRef()
  const { weightIndex, trackTest } = useTest(TESTS.BUILD_WITH_US)
  const [theWeightIndex, setTheWeightIndex] = React.useState(weightIndex)

  const isWhiteClassName = isWhite ? styles.isWhite : ""
  const menuVisibleClassName = menuVisible ? styles.menuVisible : ""
  const consentGivenClassName = isConsentGiven ? styles.isConsentGiven : ""

  const handleScroll = React.useCallback(() => {
    const sections = elementsRef.current || []
    const visibleSectionIndex = sections.findIndex(isInViewport)
    const visibleSection = sections[visibleSectionIndex]
    const computedStyle = visibleSection ? getComputedStyle(visibleSection) : {}

    if (
      !computedStyle.backgroundColor ||
      computedStyle.backgroundColor === "rgba(0, 0, 0, 0)"
    )
      return

    setMenuVisible(visibleSectionIndex >= 0)
    setColor(computedStyle.backgroundColor)
    setIsWhite(isLight(computedStyle.backgroundColor))
  }, [])

  const handleOpen = React.useCallback(() => {
    document.dispatchEvent(new Event("canonic.menu_open"))
    setIsActive(true)
  }, [])

  const giveConsent = React.useCallback(() => {
    localStorage.isConsentGiven = true
    setIsConsentGiven(true)
  }, [])

  const handleOpenKeyboard = React.useCallback(
    e => {
      if (e.keyCode !== 13) return
      handleOpen()
    },
    [handleOpen]
  )

  const getNotification = React.useCallback(
    () =>
      fetch(
        "https://can.canonic.dev/canonic-notifications-9da4e3/api/getNotification"
      )
        .then(res => res.json())
        .then(({ data }) => data),
    []
  )

  React.useEffect(() => {
    getNotification().then(setNotification)
  }, [getNotification, setNotification])

  const gridRef = React.useRef()
  const dropdownRef = React.useRef()
  const {
    handleMouseEnter,
    handleMouseLeave,
    handleClose,
    activeRect,
    dropdownRect,
    activeMenu,
    submenuOpen,
  } = useSlidingDropdown({ gridRef, dropdownRef })

  React.useEffect(() => {
    const handleClose = () => setIsActive(false)
    handleScroll()
    // @TODO: add debounce/throttle
    // document.addEventListener("scroll", handleScroll)
    document.addEventListener("canonic.menu_close", handleClose)
    return () => {
      // document.removeEventListener("scroll", handleScroll)
      document.removeEventListener("canonic.menu_close", handleClose)
    }
  }, [handleScroll])

  React.useEffect(() => {
    setIsConsentGiven(localStorage.getItem("isConsentGiven"))
  }, [])

  // React.useLayoutEffect(() => {
  //   const interval = setInterval(() => {
  //     elementsRef.current = [
  //       ...document.getElementsByTagName("section"),
  //       ...document.getElementsByTagName("ul"),
  //       ...document.getElementsByTagName("footer"),
  //     ]

  //     if (elementsRef.current.length > 2) {
  //       handleScroll()
  //       clearInterval(interval)
  //     }
  //   }, 200)
  //   return () => {
  //     clearInterval(interval)
  //   }
  // }, [])

  const submenuActiveClassName = submenuOpen ? styles.active : ""
  const isActiveRoute = routes =>
    routes.includes(location.pathname.split("/")[1])

  const headerClassNames = [
    styles.header,
    menuVisibleClassName,
    isWhiteClassName,
  ]
    .join(" ")
    .trim()

  React.useEffect(
    () => setTheWeightIndex(localStorage.getItem("ab_test_buildWithUs")),
    []
  ) //Wasn't correctly fetching weightIndex from the hook on initial load, ergo fetching it directly froom localstorage.

  console.log("notification", notification)
  if (location.pathname.includes("/docs")) return <BlogHeader />
  return (
    <div className={styles.headerWrapper}>
      {location.pathname.includes("video-sales-letter") && (
        <div className={styles.vslBanner}>
          <p>
            <a
              href="https://calendly.com/d/2jk-7gz-j7g/30min"
              target="_blank"
              className={styles.vslBannerCall}
            >
              Book a 20-minute discovery call
            </a>{" "}
            and get access to 1 year of Canonic’s essential plan
          </p>
        </div>
      )}
      {notification && notification.active && notification.text && !dismissed && (
        <a href={notification.link} target="_blank">
          <div className={`${styles.phStrip} ${consentGivenClassName}`}>
            {notification.text}
            <span className={styles.cta}>{notification.cta}</span>
            <span
              onClick={e =>
                e.preventDefault() + e.stopPropagation() + setDismissed(true)
              }
              className={styles.dismiss}
            >
              <CrossIcon />
            </span>
          </div>
        </a>
      )}
      <div className={`${styles.cookieStrip} ${consentGivenClassName}`}>
        <p>
          We use cookies to personalize your experience.
          <a href="/privacy-policy" target="_blank" rel="noopener noferrer">
            Learn More
          </a>
        </p>
        <button onClick={giveConsent}>Dismiss</button>
      </div>
      {location.pathname.match(
        /openai|30-days-of-canonic|ecommerce|refund-tools/
      ) && !location.pathname.match(/\/integrations\/openai/) ? (
        <header style={{ background: color }} className={headerClassNames}>
          <div className={`${styles.headerColumn} ${styles.noNavLink}`}>
            <div className={styles.headerColumn}>
              <Link to="/" entry={{ length: 0.3 }} exit={{ length: 0.3 }}>
                <div className={styles.headerLogo}>Canonic</div>
              </Link>
            </div>
            <div
              className={`${styles.headerColumn} ${styles.headerColumnThirtyDays}`}
            >
              {/* <HeaderLink
                nonPrimary
                value="30DaysOfCanonic"
                active={isActiveRoute(["features"])}
                onMouseEnter={e => handleMouseEnter(e, true)}
                onMouseLeave={e => handleMouseLeave(e, true)}
              >
                <Link
                  to="/30-days-of-canonic"
                  entry={{ length: 0.3 }}
                  exit={{ length: 0.3 }}
                >
                  <span className={styles.headerColumnThirtyDaysPipe}>|</span>{" "}
                  30DaysOfCanonic
                </Link>
              </HeaderLink> */}
              <HeaderLink
                className={`${styles.signup} ${styles.headerColumnThirtyDaysButton}`}
              >
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={`${appUrl}`}
                  onClick={() =>
                    trackV2("Clicked on a CTA", {
                      category: "Click",
                      label: "Start for free",
                    })
                  }
                >
                  <Button isPrimary>Start for Free</Button>
                </a>
              </HeaderLink>
            </div>
          </div>
          <HeaderHamburger
            onClick={handleOpen}
            active={isActive}
            onKeyPress={handleOpenKeyboard}
          />
        </header>
      ) : (
        <header style={{ background: color }} className={headerClassNames}>
          <div className={styles.headerInner}>
            <div className={styles.headerColumn}>
              <Link to="/" entry={{ length: 0.3 }} exit={{ length: 0.3 }}>
                <div className={styles.headerLogo}>Canonic</div>
              </Link>
            </div>
            <div className={`${styles.headerColumn}`}>
              <div className={`${styles.headerColumn} ${styles.desktopOnly}`}>
                {/* <HeaderLink
                  nonPrimary
                  value="PLATFORM"
                  active={isActiveRoute(["features"])}
                  onMouseEnter={e => handleMouseEnter(e, true)}
                  onMouseLeave={e => handleMouseLeave(e, true)}
                >
                  <Link
                    to="/features"
                    entry={{ length: 0.3 }}
                    exit={{ length: 0.3 }}
                  >
                    Platform
                  </Link>
                </HeaderLink> */}

                <HeaderLink
                  nonPrimary
                  active={isActiveRoute(["pricing"])}
                  onMouseEnter={e => handleMouseEnter(e, true)}
                  onMouseLeave={e => handleMouseLeave(e, true)}
                  value="PRICING"
                >
                  <Link
                    to="/pricing"
                    entry={{ length: 0.3 }}
                    exit={{ length: 0.3 }}
                  >
                    Pricing
                  </Link>
                </HeaderLink>
                <HeaderLink
                  nonPrimary
                  value="RESOURCES"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  active={isActiveRoute(["solutions"])}
                >
                  Resources
                </HeaderLink>
              </div>
            </div>
            <div className={`${styles.headerColumn} ${styles.alignRight}`}>
              <HeaderLink desktopOnly>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={
                    (location.pathname === "/features/saas-api-pack" &&
                      SAAS_PACK_LOGIN_URL) ||
                    (location.pathname === "/features/multiple-integrations" &&
                      INTEGRATIONS_PACK_LOGIN_URL) ||
                    (location.pathname === "/features/multiple-data-sources/" &&
                      MULTIPLE_DS_LOGIN_URL) ||
                    (location.pathname && appLoginUrl)
                  }
                  onClick={() =>
                    trackV2("Clicked on a CTA", {
                      category: "Click",
                      label: "Login",
                    })
                  }
                >
                  Login
                </a>
              </HeaderLink>
              <HeaderLink className={styles.signup}>
                <a
                  rel="noreferrer"
                  target="_blank"
                  href={
                    (location.pathname === "/features/saas-api-pack" &&
                      SAAS_PACK_HEADER_URL) ||
                    (location.pathname === "/features/multiple-integrations" &&
                      INTEGRATIONS_PACK_HEADER_URL) ||
                    (location.pathname === "/features/multiple-data-sources/" &&
                      MULTIPLE_DS_HEADER_URL) ||
                    (location.pathname && appUrl)
                  }
                  onClick={() =>
                    trackV2("Clicked on a CTA", {
                      category: "Click",
                      label: "Start for free",
                    })
                  }
                >
                  <Button isPrimary>Start for Free</Button>
                </a>
              </HeaderLink>
              <HeaderHamburger
                onClick={handleOpen}
                active={isActive}
                onKeyPress={handleOpenKeyboard}
              />
            </div>
            <div
              style={{
                transform: `translateX(${activeRect.left}px)`,
                width: activeRect.width || 0,
              }}
              className={styles.headerUnderline}
            />
            <div
              ref={dropdownRef}
              className={`${styles.headerDropdown} ${submenuActiveClassName}`}
              style={{
                top: 55,
                transform: `translateX(${activeRect.left}px)`,
                width: dropdownRect.width,
                height: dropdownRect.height,
              }}
            >
              <Switch duration={400} value={activeMenu}>
                <Case RESOURCES>
                  <div onMouseOut={handleMouseLeave} ref={gridRef}>
                    <div className={styles.headerGridItems}>
                      {/* <HeaderGridItem
                  title="Blog"
                  subtitle="Learn, explore & discover all things Canonic."
                  icon={WebsitesIcon}
                  to="https://blog.canonic.dev"
                  onClick={handleClose}
                /> */}
                      <HeaderGridItem
                        title="Documentation"
                        subtitle="Guides, recipes and reference documentation.."
                        icon={AppsIcon}
                        to="/docs"
                        onClick={handleClose}
                      />
                      <HeaderGridItem
                        title="Blog"
                        subtitle="Articles, guides and news for all things Canonic."
                        icon={WebsitesIcon}
                        to="/blog"
                        onClick={handleClose}
                      />
                      <HeaderGridItem
                        title="Community"
                        subtitle="Get instant support from Canonic & it's community."
                        icon={DiscordIcon}
                        to={DISCORD_INVITE}
                        onClick={handleClose}
                      />
                      <HeaderGridItem
                        title="Videos"
                        subtitle="Video tutorials, guides & walkthroughs."
                        icon={YouTubeIcon}
                        to={"https://www.youtube.com/@canonichq"}
                        onClick={handleClose}
                      />
                    </div>
                  </div>
                </Case>
                <Case SUPPORT>
                  <div onMouseOut={handleMouseLeave} ref={gridRef}>
                    <div className={styles.headerGridItems}>
                      <HeaderGridItem
                        title="Contact Us"
                        icon={ContactIcon}
                        onClick={handleClose}
                        className={styles.fullHeight}
                      >
                        <div className={styles.contactWrapper}>
                          <div className={styles.contact}>
                            <p>
                              For sales enquiries
                              <br />
                              <strong>
                                <a
                                  href="mailto:sales@canonic.dev"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  sales@canonic.dev
                                </a>
                              </strong>
                            </p>
                          </div>
                          <div className={styles.contact}>
                            <p>
                              For support requests
                              <br />
                              <strong>
                                <a
                                  href="mailto:support@canonic.dev"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  support@canonic.dev
                                </a>
                              </strong>
                            </p>
                          </div>
                          <div className={styles.contact}>
                            <p>
                              For ideas & everything else
                              <br />
                              <strong>
                                <a
                                  href="mailto:hello@canonic.dev"
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  hello@canonic.dev
                                </a>
                              </strong>
                            </p>
                          </div>
                          <Link
                            to={
                              theWeightIndex === "0"
                                ? "get-a-demo"
                                : "build-with-us"
                            }
                            entry={{ length: 0.3 }}
                            exit={{ length: 0.3 }}
                          >
                            <button
                              className={styles.contactButton}
                              onClick={() =>
                                trackTest(
                                  TESTS.BUILD_WITH_US.tests[theWeightIndex]
                                    .name,
                                  TESTS.BUILD_WITH_US.key,
                                  "Header"
                                )
                              }
                            >
                              {theWeightIndex === "0"
                                ? "Get a Demo"
                                : "Build with Us"}
                            </button>
                          </Link>
                        </div>
                      </HeaderGridItem>
                    </div>
                  </div>
                </Case>
              </Switch>
            </div>
          </div>
        </header>
      )}
    </div>
  )
}

function isInViewport(el) {
  const rect = el.getBoundingClientRect()
  return (
    // (rect.top >= 0 &&
    //   rect.top <=
    //     (window.innerHeight || document.documentElement.clientHeight)) ||
    rect.top <= 100 && rect.bottom >= 100 //&&
    //rect.bottom <= (window.innerHeight || document.documentElement.clientHeight)
  )
}

function isLight(rgb) {
  if (!rgb) return false
  const [c_r, c_g, c_b] = rgb
    .split("(")[1]
    .split(")")[0]
    .split(",")
    .map(hex => parseInt(hex, 10))
  const brightness = (c_r * 299 + c_g * 587 + c_b * 114) / 1000
  return brightness > 155
}
