import React from "react"

const useTest = (test, children) => {
  const [weightIndex, setWeightIndex] = React.useState()

  const reactChildren = React.Children.toArray(children)
  const getRandomIndex = arrayOfIndex => {
    const randomNumber = Math.floor(Math.random() * 100) + 1
    //Currently only supports 2 sets of weight.
    if (randomNumber <= arrayOfIndex[0]) return 0
    else return 1
  }
  React.useEffect(() => {
    const weights = test.tests.map(test => test.weight)

    const weightIndex =
      localStorage[`ab_test_${test.key}`] || getRandomIndex(weights)
    localStorage.setItem(`ab_test_${test.key}`, weightIndex)
    setWeightIndex(weightIndex)
  }, [children, test.tests, test.key])

  const trackTest = (testName, test, placement, submitEvent) => {
    if (process.env.NODE_ENV === "development") return
    window.analytics.track(`${testName}:${submitEvent ? "Submit" : "Click"}`, {
      category: `AbTest ${test}`,
      ...(placement && { placement }),
    })
  }
  return {
    weightIndex,
    variation: reactChildren[weightIndex],
    trackTest,
  }
}

export default useTest
