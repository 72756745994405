import React from "react"

import * as styles from "./Header.module.scss"

export default function Header() {
  React.useEffect(() => {
    if (!window || !window.docsearch) return

    // need this because it the plugin doesn't load
    // correctly the first time
    window.docsearch({
      appId: "08YZR3D5OV",
      apiKey: "c8fc36f67d890afd4a5d790c448525dc",
      indexName: "canonic",
      inputSelector: "#doc-search",
    })
  }, [])

  return (
    <div className={styles.header}>
      <div className={styles.headerLeftColumn}>
        <a
          className={styles.headerCanonicLogo}
          href="/docs/getting-started/introduction"
        >
          <strong>CANONIC</strong> Documentation
        </a>
      </div>
      <div className={styles.headerRightColumn}>
        <div className={styles.headerRightColumnSearch}>
          <div className={styles.headerRightColumnSearchIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
            >
              <path
                fill="#4E61DA"
                d="M14.2945205,12.6760548 L10.1397808,8.52054795 C10.7787945,7.68150685 11.1383836,6.60273973 11.1383836,5.44380822 C11.1383836,2.56709589 8.82128767,0.25 5.94457534,0.25 C3.06786301,0.25 0.75,2.56786301 0.75,5.44457534 C0.75,8.32128767 3.06709589,10.6383836 5.94380822,10.6383836 C7.10273973,10.6383836 8.1410411,10.2787945 9.02054795,9.63978082 L13.1760548,13.7952877 C13.3356222,13.9548551 13.5356438,14.0350137 13.7356712,14.0350137 C13.9356986,14.0350137 14.1349589,13.9548551 14.2952877,13.7952877 C14.6144301,13.4753973 14.6144301,12.9959452 14.2945384,12.676074 L14.2945205,12.6760548 Z M5.94457534,9.04046575 C3.94660274,9.04046575 2.34868493,7.44254795 2.34868493,5.44457534 C2.34868493,3.44660274 3.94660274,1.84868493 5.94457534,1.84868493 C7.94254795,1.84868493 9.54046575,3.44660274 9.54046575,5.44457534 C9.54046575,7.44178082 7.9418,9.04046575 5.94457534,9.04046575 Z"
                transform="translate(-.75 -.25)"
              />
            </svg>
          </div>
          <input
            id="doc-search"
            type="text"
            placeholder="Start typing to search..."
            spellCheck={false}
          />
        </div>
        <div className={styles.headerRightColumnCta}>
          <a href="https://app.canonic.dev/login" target="__blank">
            Login to Canonic
          </a>
        </div>
      </div>
    </div>
  )
}
