import React from "react"
import Link from "gatsby-plugin-transition-link"

import useOnClickOutside from "@src/hooks/useOnClickOutside"
import LinkedinIcon from "@src/icons/footer/linkedin.svg"
import TwitterIcon from "@src/icons/footer/twitter.svg"
import DiscordIcon from "@src/icons/footer/discord.svg"
import YoutubeIcon from "@src/icons/footer/youtube.svg"
import MediumIcon from "@src/icons/footer/medium.svg"
import { DISCORD_INVITE } from "@src/const"
import { TESTS } from "@src/data/test"
import useTest from "@src/hooks/useTest"

import * as styles from "./Nav.module.scss"

const Nav = ({ className = "", location }) => {
  const navRef = React.useRef()
  const [isActive, setIsActive] = React.useState(false)
  const activeClassName = isActive ? styles.active : ""
  const { weightIndex, trackTest } = useTest(TESTS.BUILD_WITH_US)

  const getColumn = React.useCallback(() => {
    switch (location.pathname.replace(/\/$/, "")) {
      case "":
        return "HOME"
      case "/features":
      case "/pricing":
      case "/enterprise":
      case "/security":
        return "PRODUCT"
      // case "/solutions/for-websites":
      // case "/solutions/for-apps":
      // case "/solutions/for-backend":
      // case "/solutions/for-omnichannel":
      //   return "SOLUTIONS"
      case "/why-canonic":
      case "/about-us":
      case "/careers":
        return "COMPANY"
      default:
        return ""
    }
  }, [location.pathname])

  const [activeColumn, setActiveColumn] = React.useState(() =>
    getColumn(location.pathname)
  )

  const handleClose = () => {
    document.dispatchEvent(new Event("canonic.menu_close"))
    setIsActive(false)
    setActiveColumn(getColumn(location.pathname))
  }

  const handleCloseKeyboard = e => {
    if (e.keyCode !== 13) return
    handleClose()
  }

  React.useEffect(() => {
    const handleOpen = () => setIsActive(true)
    document.addEventListener("canonic.menu_open", handleOpen)
    return () => {
      document.removeEventListener("canonic.menu_open", handleOpen)
    }
  }, [])

  React.useEffect(() => {
    setActiveColumn(getColumn(location.pathname))
  }, [location.pathname, getColumn])

  useOnClickOutside(navRef, () => {
    handleClose()
  })

  return (
    <nav
      ref={navRef}
      key="nav"
      className={`${styles.nav} ${className} ${activeClassName}`}
    >
      <div
        role="button"
        tabIndex="0"
        onKeyPress={handleCloseKeyboard}
        onClick={handleClose}
        className={`${styles.navCross} ${activeClassName}`}
      >
        <svg
          width="28"
          height="28"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2 7C2 6.44772 2.44772 6 3 6H25C25.5523 6 26 6.44772 26 7C26 7.55228 25.5523 8 25 8H3C2.44772 8 2 7.55228 2 7Z"
            fill="#DBDFF7"
          />
          <path
            d="M2 14C2 13.4477 2.44772 13 3 13H25C25.5523 13 26 13.4477 26 14C26 14.5523 25.5523 15 25 15H3C2.44772 15 2 14.5523 2 14Z"
            fill="#DBDFF7"
          />
          <path
            d="M2 21C2 20.4477 2.44772 20 3 20H25C25.5523 20 26 20.4477 26 21C26 21.5523 25.5523 22 25 22H3C2.44772 22 2 21.5523 2 21Z"
            fill="#DBDFF7"
          />
        </svg>
      </div>
      <div className={styles.navItems}>
        <div
          className={`${styles.navHome} ${
            activeColumn === "HOME" ? styles.active : ""
          }`}
        >
          <Link
            to={"/"}
            entry={{ length: 0.3 }}
            exit={{ length: 0.3 }}
            onClick={handleClose}
          >
            <div>Home</div>
          </Link>
        </div>
        <NavColumn active={activeColumn === "PRODUCT"} title="Product">
          <NavLink onClick={handleClose} href="/pricing">
            Pricing
          </NavLink>
        </NavColumn>
        <NavColumn active={activeColumn === "RESOURCES"} title="Resources">
          <NavLink onClick={handleClose} href="/blog">
            Blog
          </NavLink>
          <NavLink onClick={handleClose} href="/docs">
            Documentation
          </NavLink>
          <NavLink onClick={handleClose} href={DISCORD_INVITE}>
            Community
          </NavLink>
        </NavColumn>
        {/* <NavColumn active={activeColumn === "COMPANY"} title="Company">
          <NavLink onClick={handleClose} href="/why-canonic">
            Why Canonic
          </NavLink>
          <NavLink onClick={handleClose} href="/about-us">
            About Us
          </NavLink>
          <NavLink onClick={handleClose} href="/careers">
            Careers
          </NavLink>
        </NavColumn> */}

        <div className={styles.navContact}>
          <a
            className={`${styles.navGetADemo} ${styles.filled}`}
            href={weightIndex === "0" ? "/get-a-demo" : "/build-with-us"}
            onClick={() =>
              trackTest(
                TESTS.BUILD_WITH_US.tests[weightIndex].name,
                TESTS.BUILD_WITH_US.key,
                "Header"
              )
            }
          >
            {weightIndex === "0" ? "Get a Demo" : "Build with Us"}
          </a>
        </div>
        <div className={styles.navSocial}>
          <a
            className={styles.navSocialLogo}
            href="https://www.linkedin.com/company/canonichq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.navSocialLogoImage}
              alt="linkedin"
              src={LinkedinIcon}
            />
          </a>
          <a
            className={styles.navSocialLogo}
            href="https://twitter.com/CanonicHQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.navSocialLogoImage}
              alt="twitter"
              src={TwitterIcon}
            />
          </a>
          <a
            className={styles.navSocialLogo}
            href={DISCORD_INVITE}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.navSocialLogoImage}
              alt="discord"
              src={DiscordIcon}
            />
          </a>
          <a
            className={styles.navSocialLogo}
            href="https://www.youtube.com/@canonichq"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.navSocialLogoImage}
              alt="youtube"
              src={YoutubeIcon}
            />
          </a>
          <a
            className={styles.navSocialLogo}
            href="https://medium.com/@canonicHQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className={styles.navSocialLogoImage}
              alt="medium"
              src={MediumIcon}
            />
          </a>
        </div>
      </div>
    </nav>
  )
}

const NavColumn = ({ active, title, children, onChange }) => {
  const activeClassName = active ? styles.active : ""
  const listRef = React.useRef()
  // const [rect, setRect] = React.useState({ height: "auto" })
  // const height = active ? rect.height : 0
  const height = "auto"

  const handleKey = React.useCallback(
    e => {
      if (e.keyCode !== 13) return
      onChange()
    },
    [onChange]
  )
  // React.useLayoutEffect(() => {
  //   if (listRef.current) setRect(listRef.current.getBoundingClientRect())
  // }, [listRef.current])

  return (
    <div
      role="button"
      tabIndex="0"
      onKeyPress={handleKey}
      onClick={onChange}
      className={styles.navColumn}
    >
      <div className={`${styles.navTitle} ${activeClassName}`}>{title}</div>
      <div className={styles.navListWrapper} style={{ height }}>
        <ul ref={listRef} className={styles.navList}>
          {children}
        </ul>
      </div>
    </div>
  )
}

const NavLink = ({ children, href, onClick }) => {
  if (href.includes("http://") || href.includes("https://")) {
    return (
      <>
        <a
          onClick={onClick}
          className={styles.navLink}
          href={href}
          target="_blank"
          rel="noreferrer"
        >
          {children}
        </a>
        <br />
      </>
    )
  }
  return (
    <>
      <Link
        onClick={onClick}
        activeClassName={styles.active}
        className={styles.navLink}
        to={href}
        entry={{ length: 0.3 }}
        exit={{ length: 0.3 }}
      >
        {children}
      </Link>
      <br />
    </>
  )
}

export default Nav
