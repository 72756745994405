export const TESTS = {
  BUILD_WITH_US: {
    key: "buildWithUs",
    tests: [
      {
        name: "getADemo",
        key: "demo",
        weight: 50,
      },
      {
        name: "buildWithUs",
        key: "build",
        weight: 50,
      },
    ],
  },
  HOMEPAGE_TEST: {
    key: "homepageTest",
    tests: [
      {
        name: "backend",
        key: "backend",
        weight: 30,
      },
      {
        name: "app",
        key: "app",
        weight: 70,
      },
    ],
  },
}
export const content = weightIndex => {
  return {
    heading:
      weightIndex === "0"
        ? "Supercharge the back to your frontend."
        : "Supercharge your business with a no-code development platform",
    subheading:
      weightIndex === "0"
        ? "Create, manage & deploy powerful serverless backends in a matter of minutes with an intuitive low-code interface."
        : "Build tools to help your business - without the need for developer bandwidth.",
    metrics: weightIndex === "0" ? "APIs Deployed" : "Projects deployed",
    howItWorks:
      weightIndex === "0"
        ? "Build entire backends, without writing a single line of code."
        : `Build entire app, without writing a single line of code.`,
    topBenefits:
      weightIndex === "0"
        ? "Build dynamic applications with easy-to-integrate APIs."
        : "Build dynamic applications with intuitive mindmap interface.",
  }
}
