import React from "react"

export default function getAppUrl(location, redirectBackTo = "") {
  const origin = location?.origin || ""
  const searchParams = new URLSearchParams(location?.search || "")
  if (redirectBackTo) searchParams.set("redirectBackTo", redirectBackTo)
  const postfix = searchParams.toString() ? `?${searchParams}` : ""

  return origin.includes("staging")
    ? `https://app.staging.canonic.dev/signup${postfix}`
    : origin.includes("localhost")
    ? `http://localhost:3000/signup${postfix}`
    : `https://app.canonic.dev/signup${postfix}`
}

getAppUrl.hook = function useAppUrl(location) {
  const [url, setUrl] = React.useState()

  React.useEffect(() => {
    setUrl(getAppUrl(location || window.location))
  }, [location])

  return url
}
