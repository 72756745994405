import React from "react"
import Link from "gatsby-plugin-transition-link"
import * as styles from "../Header.module.scss"

export default function HeaderGridItem({
  title,
  subtitle,
  icon,
  className,
  to,
  onClick,
  children,
}) {
  const item = (
    <>
      <div className={styles.headerGridItemIcon}>
        <img src={icon} alt={title} />
      </div>
      <div className={styles.headerGridItemText}>
        <div className={styles.headerGridItemTextTitle}>{title}</div>
        {subtitle && (
          <div className={styles.headerGridItemTextSubtitle}>{subtitle}</div>
        )}
        {children}
      </div>
    </>
  )

  return to ? (
    <Link
      to={to}
      onClick={onClick}
      className={`${styles.headerGridItem} ${className}`}
      entry={{ length: 0.3 }}
      exit={{ length: 0.3 }}
    >
      {item}
    </Link>
  ) : (
    <div className={`${styles.headerGridItem} ${className}`}>{item}</div>
  )
}
