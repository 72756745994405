import React from "react"
import Nav from "@src/components/Nav"
import Header from "@src/components/Header"

export const wrapPageElement = ({ element, props }) => {
  return <Layout element={element} props={props} />
}

const initializeSegment = () => {
  //prettier-ignore
  if (process.env.NODE_ENV === 'development') return;
  let analytics = (window.analytics = window.analytics || [])
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.")
    else {
      analytics.invoked = !0
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
        "addSourceMiddleware",
        "addIntegrationMiddleware",
        "setAnonymousId",
        "addDestinationMiddleware",
      ]
      analytics.factory = function (e) {
        return function () {
          let t = Array.prototype.slice.call(arguments)
          t.unshift(e)
          analytics.push(t)
          return analytics
        }
      }
      for (let e = 0; e < analytics.methods.length; e++) {
        let key = analytics.methods[e]
        analytics[key] = analytics.factory(key)
      }
      analytics.load = function (key, e) {
        let t = document.createElement("script")
        t.type = "text/javascript"
        t.async = !0
        t.src =
          "https://cdn.segment.com/analytics.js/v1/" + key + "/analytics.min.js"
        let n = document.getElementsByTagName("script")[0]
        n.parentNode.insertBefore(t, n)
        analytics._loadOptions = e
      }
      analytics.SNIPPET_VERSION = "4.13.1"
      analytics.load("i9v3YAkQYoMLrWMasuxToPVD6f9yDOsS")
    }
}

function Layout({ element, props }) {
  React.useEffect(() => {
    initializeSegment()
  }, [])

  React.useEffect(() => {
    if (process.env.NODE_ENV === "development") return
    window.analytics.page()
  }, [props.path])

  return (
    <div>
      <Header {...props} />
      <Nav {...props} />
      {element}
    </div>
  )
}
