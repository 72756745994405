import React from "react"
import * as styles from "../Header.module.scss"

export default function HeaderHamburger({ onClick, active, onKeyPress }) {
  const activeClassName = active ? styles.active : ""
  return (
    <div
      tabIndex="0"
      role="button"
      onClick={onClick}
      onKeyPress={onKeyPress}
      className={`${styles.headerLink} ${activeClassName} ${styles.hideOnDesktop}`}
    >
      <svg
        width="28"
        height="28"
        viewBox="0 0 28 28"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 7C2 6.44772 2.44772 6 3 6H25C25.5523 6 26 6.44772 26 7C26 7.55228 25.5523 8 25 8H3C2.44772 8 2 7.55228 2 7Z"
          fill="#DBDFF7"
        />
        <path
          d="M2 14C2 13.4477 2.44772 13 3 13H25C25.5523 13 26 13.4477 26 14C26 14.5523 25.5523 15 25 15H3C2.44772 15 2 14.5523 2 14Z"
          fill="#DBDFF7"
        />
        <path
          d="M2 21C2 20.4477 2.44772 20 3 20H25C25.5523 20 26 20.4477 26 21C26 21.5523 25.5523 22 25 22H3C2.44772 22 2 21.5523 2 21Z"
          fill="#DBDFF7"
        />
      </svg>
    </div>
  )
}
