import React from "react"
import * as styles from "../Header.module.scss"

export default function HeaderHamburger({
  onClick,
  children,
  className,
  value,
  onMouseEnter,
  onMouseLeave,
  desktopOnly,
  active,
  nonPrimary,
}) {
  const activeClassName = active ? styles.active : ""
  const nonPrimaryClassName = nonPrimary ? styles.nonPrimary : ""
  const desktopOnlyClassName = desktopOnly ? styles.hideOnTablet : ""
  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseOut={onMouseLeave}
      value={value}
      onClick={onClick}
      className={`${styles.headerLink} ${nonPrimaryClassName} ${desktopOnlyClassName} ${activeClassName} ${className}`}
    >
      {children}
    </div>
  )
}
