import React from "react"

export default function useSlidingDropdown({ gridRef, dropdownRef }) {
  const [activeRect, setActiveRect] = React.useState({ left: 400 })
  const [dropdownRect, setDropdownRect] = React.useState({})
  const [activeMenu, setActiveMenu] = React.useState("")
  const [submenuOpen, setSubmenuOpen] = React.useState(false)
  const timeoutRef = React.useRef()

  const handleMouseEnter = React.useCallback((e, noDropdown) => {
    const { value } = e.currentTarget.attributes.value
    const boundingBox = e.currentTarget.getBoundingClientRect()
    clearTimeout(timeoutRef.current)
    setActiveRect(boundingBox)
    setActiveMenu(`${value}`)
    if (noDropdown) setSubmenuOpen(false)
    else
      setTimeout(() => {
        setSubmenuOpen(true)
      }, 100)
  }, [])

  const handleMouseLeave = React.useCallback(
    e => {
      clearTimeout(timeoutRef.current)
      if (dropdownRef?.current?.contains(e.relatedTarget)) return
      if (e.target.contains(e.relatedTarget)) return
      timeoutRef.current = setTimeout(() => {
        setSubmenuOpen(false)
        timeoutRef.current = setTimeout(() => {
          setActiveMenu("")
          setActiveRect({})
        }, 200)
      }, 200)
    },
    [gridRef.current]
  )

  const handleClose = React.useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setSubmenuOpen(false)
      timeoutRef.current = setTimeout(() => {
        setActiveMenu("")
        setActiveRect({})
      }, 200)
    }, 200)
  }, [gridRef.current])

  React.useLayoutEffect(() => {
    setTimeout(() => {
      if (!gridRef.current) return
      const rect = gridRef.current.getBoundingClientRect()
      if (
        dropdownRect.width === rect.width &&
        dropdownRect.height === rect.height
      )
        return
      setDropdownRect(rect)
    }, 100)
  }, [gridRef.current, activeMenu, submenuOpen])

  return {
    handleClose,
    handleMouseEnter,
    handleMouseLeave,
    activeRect,
    dropdownRect,
    activeMenu,
    submenuOpen,
  }
}
